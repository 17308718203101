import { ForwardedRef, forwardRef, memo, ReactNode } from 'react'
import Link from 'next/link'
import classNames from 'classnames'

import css from './CtaLink.module.scss'

import ExternalArrow from '@/svgs/external-arrow.svg'

export type CtaLinkHandle = {
  // Fill up
}

export interface CtaLinkProps {
  className?: string
  handleRef?: ForwardedRef<CtaLinkHandle>
  children: ReactNode
  href: string
  showExternalSvg?: boolean
  withLineHover?: boolean
  withOpacityHover?: boolean
}

export interface ViewProps extends CtaLinkProps {}

// View (pure and testable component, receives props from the controller)
export const View = forwardRef<HTMLAnchorElement, ViewProps>(
  ({ className, children, href, showExternalSvg = false, withLineHover = false, withOpacityHover = false }, ref) => {
    const isExternal = href && (href.startsWith('http://') || href.startsWith('https://'))
    const isEmail = href && href.startsWith('mailto:')
    const classNamesList = classNames('CtaLink', css.root, className, {
      [css.withLineHover]: withLineHover,
      [css.withOpacityHover]: withOpacityHover,
      [css.externalSvg]: showExternalSvg
    })
    const isInternalScrollingLink = href && href.startsWith('#')

    if (isInternalScrollingLink) {
      return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <span
          className={classNamesList}
          ref={ref}
          onClick={() => {
            // Lenis smooth scrolling to #href target
            const target = href
            window.lenis.scrollTo(target, {
              duration: 2,
              offset: -24
            })
          }}
        >
          {children}
        </span>
      )
    }

    if (isExternal || isEmail) {
      return (
        <a className={classNamesList} href={href} ref={ref} target="_blank" rel="noreferrer">
          {children} {showExternalSvg ? <ExternalArrow className={css.svg} /> : null}
        </a>
      )
    }

    return (
      <Link className={classNamesList} href={href} ref={ref} scroll={false}>
        {children}
      </Link>
    )
  }
)

View.displayName = 'CtaLink-View'

// Controller (handles global state, router, data fetching, etc. Feeds props to the view component)
const CtaLink = forwardRef<HTMLAnchorElement, CtaLinkProps>((props, ref) => {
  return <View {...props} ref={ref} />
})

CtaLink.displayName = 'CtaLink'

export default memo(CtaLink)
