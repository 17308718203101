import { GetStaticProps } from 'next'

import landingCopy from '@/data/fixed-pages/copy-landing'

import PageHome, { PageHomeProps } from '@/components/PageHome/PageHome'

export const getStaticProps: GetStaticProps<PageHomeProps> = async () => {
  return {
    props: {
      head: { title: 'Designer' },
      content: landingCopy
    }
  }
}

export default PageHome
