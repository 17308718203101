import { ForwardedRef, forwardRef, memo, ReactNode, useRef } from 'react'
import classNames from 'classnames'

import css from './Heading.module.scss'

import useCombinedRefs from '@/hooks/use-combined-refs'

export type HeadingHandle = {
  //
}

export interface HeadingProps {
  className?: string
  children: ReactNode
  handleRef?: ForwardedRef<HeadingHandle>
  as?: 'h1' | 'h2' | 'h3' | 'h4'
  uppercase?: boolean
  italic?: boolean
}

export interface ViewProps extends HeadingProps {}

// View (pure and testable component, receives props from the controller)
export const View = forwardRef<HTMLHeadingElement, ViewProps>(
  ({ className, as = 'h1', uppercase = false, italic = false, children }, ref) => {
    const rootRef = useRef<HTMLHeadingElement>(null)
    const combinedRef = useCombinedRefs(ref, rootRef)
    const Tag = as

    return (
      <Tag
        className={classNames('Heading', css.root, className, css[as], {
          [css.uppercase]: uppercase,
          [css.italic]: italic
        })}
        ref={combinedRef}
      >
        {children}
      </Tag>
    )
  }
)

View.displayName = 'Heading-View'

// Controller (handles global state, router, data fetching, etc. Feeds props to the view component)
const Heading = forwardRef<HTMLHeadingElement, HeadingProps>((props, ref) => {
  return <View {...props} ref={ref} />
})

Heading.displayName = 'Heading'

export default memo(Heading)
