import { FC, Fragment, memo, useEffect, useImperativeHandle, useRef, useState } from 'react'
import classNames from 'classnames'
import gsap from 'gsap'

import css from './PageHome.module.scss'

import { LandingCopyType } from '@/data/fixed-pages/copy-landing'
import { PageHandle, PageProps } from '@/data/types'

import { updateCustomProperties } from '@/utils/basic-functions'
import { MOTION } from '@/utils/motion-values'
import sass from '@/utils/sass'

import useLayout from '@/hooks/use-layout'
import { useRefs } from '@/hooks/use-refs'

import BaseImage from '@/components/BaseImage/BaseImage'
import CtaLink from '@/components/CtaLink/CtaLink'
import Heading from '@/components/Heading/Heading'
import Label from '@/components/Label/Label'
import Paragraph from '@/components/Paragraph/Paragraph'

export interface PageHomeProps extends PageProps {
  className?: string
  content: LandingCopyType
}

export type ViewRefs = {
  root: HTMLDivElement
  headingWrapper: HTMLDivElement
  heading1: HTMLHeadingElement
  heading2: HTMLHeadingElement
  heading3: HTMLHeadingElement
  image: HTMLImageElement
  scrollContent: HTMLDivElement
}

const View: FC<PageHomeProps> = ({ className, content, onReady }) => {
  const layout = useLayout()
  const viewRefs = useRefs<ViewRefs>()
  const handleRef = useRef<PageHandle>(null)
  const [ready, setReady] = useState(false)

  useEffect(() => {
    gsap.set(viewRefs.root.current, { opacity: 0 })

    const readyTimeout = setTimeout(() => {
      onReady?.(handleRef)
      setReady(true)
    }, MOTION.general.delayForLayout)

    return () => {
      if (readyTimeout) clearTimeout(readyTimeout)
    }
  }, [onReady, viewRefs])

  useImperativeHandle(handleRef, () => ({
    animateIn: () => {
      const linkSections = Array.from(document.querySelectorAll(`.${css.linkSection}`))
      const image = viewRefs.image.current
      const description = document.querySelector(`.${css.description}`)

      return gsap
        .timeline()
        .set([viewRefs.root.current, viewRefs.headingWrapper.current], { opacity: 1 }, 0)
        .textRiseByCharsIn(
          viewRefs.heading1.current!,
          {
            duration: 1.5,
            charDuration: 1.5,
            charOffset: 0.075,
            ease: 'ease-split-text',
            immediateRender: true
          },
          0
        )
        .textRiseByCharsIn(
          viewRefs.heading2.current!,
          {
            duration: 1.5,
            charDuration: 1.5,
            charOffset: 0.075,
            ease: 'ease-split-text',
            immediateRender: true
          },
          '<+0.334'
        )
        .from(
          linkSections,
          { y: 45, duration: 1.2, stagger: 0.15, ease: 'ease-transform', immediateRender: true },
          '<+0.334'
        )
        .from(
          linkSections,
          { opacity: 0, duration: 0.8, stagger: 0.15, ease: 'ease-opacity', immediateRender: true },
          '<'
        )

        .from(image, { y: 45, duration: 1.2, ease: 'ease-transform', immediateRender: true }, '<+0.667')
        .from(image, { opacity: 0, duration: 0.8, ease: 'ease-opacity', immediateRender: true }, '<')

        .from(
          [description?.childNodes],
          { y: 45, duration: 1.2, stagger: 0.15, ease: 'ease-transform', immediateRender: true },
          '<+0.2'
        )
        .from(
          [description?.childNodes],
          { opacity: 0, duration: 0.8, stagger: 0.15, ease: 'ease-opacity', immediateRender: true },
          '<'
        )
    },
    animateOut: () => {
      return gsap.timeline().to(viewRefs.root.current, { autoAlpha: 0, ease: 'ease-opacity', duration: 0.8 }, 0)
    }
  }))

  useEffect(() => {
    if (!ready) return

    const bioSections = Array.from(document.querySelectorAll(`.${css.bioSection}`))

    const scrollSectionTimeline = gsap
      .timeline({
        scrollTrigger: layout.tablet
          ? undefined
          : {
              trigger: viewRefs.scrollContent.current!,
              start: 'top 70%',
              end: 'bottom bottom'
            },
        delay: layout.tablet ? 0.1 + 1.446 : 0 // @NOTE: This is the delay of the last animation in the animateIn timeline
      })
      .from(bioSections, { y: 50, duration: 1.2, stagger: 0.15, ease: 'ease-transform', immediateRender: true }, 0)
      .from(bioSections, { opacity: 0, duration: 0.8, stagger: 0.15, ease: 'ease-opacity', immediateRender: true }, '<')

    return () => {
      scrollSectionTimeline?.kill()
    }
  }, [layout.tablet, viewRefs, ready])

  useEffect(() => {
    updateCustomProperties({
      backButtonVisibility: 'hidden',
      backButtonColor: sass.transparent,
      backButtonOpacity: '0',
      navButtonBackground: sass.black,
      navButtonColor: sass.white,
      navButtonBorder: 'transparent',
      backButtonTransitionDuration: '0.4s'
    })
  }, [])

  return (
    <div className={classNames('PageHome', css.root, className)} ref={viewRefs.root}>
      <div className={css.content}>
        <div className={css.name} ref={viewRefs.headingWrapper}>
          <Heading ref={viewRefs.heading1} as="h1" className={css.titleHeaders} uppercase>
            {content.header[0]}
          </Heading>
          <Heading ref={viewRefs.heading2} as="h1" italic className={classNames(css.titleHeaders, css.specialMargin)}>
            {content.header[1]}
          </Heading>
        </div>
        <div className={css.links}>
          {content.links.map((link, i) => {
            return (
              <div className={css.linkSection} key={i}>
                <Label labelType={2} className={css.headerLabel}>
                  {link.label}
                </Label>
                {link.ctas.map((cta, i) => {
                  return (
                    <CtaLink className={css.ctaLink} key={`${i}-${cta.label}`} href={cta.link} withLineHover>
                      <Label labelType={2}>{cta.label}</Label>
                    </CtaLink>
                  )
                })}
              </div>
            )
          })}
        </div>
        <div className={css.bio} id="scroll">
          <BaseImage ref={viewRefs.image} className={css.image} data={content.image} fill={false} priority />
          <div className={css.description}>
            <Paragraph>{content.description[0]}</Paragraph>
            <Paragraph>{content.description[1]}</Paragraph>
          </div>
        </div>
      </div>
      <div className={css.scrollContent}>
        <div className={css.position} ref={viewRefs.scrollContent}>
          {content.bio.map((sections, i) => {
            const isArraySection = sections.length > 1
            return (
              <div className={css.bioSection} key={i}>
                {sections.map((content, i) => {
                  if (isArraySection) {
                    return (
                      <div className={css.miniSection} key={i}>
                        <Label className={css.label} labelType={2}>
                          {content.title}
                        </Label>
                        {content.paragraphs.map((paragraph, i) => (
                          <Paragraph className={css.paragraph} key={i}>
                            {paragraph}
                          </Paragraph>
                        ))}
                      </div>
                    )
                  } else {
                    return (
                      <Fragment key={i}>
                        <Label className={css.label} labelType={2}>
                          {content.title}
                        </Label>
                        {content.paragraphs.map((paragraph, i) => {
                          return (
                            <Paragraph className={css.paragraph} key={i}>
                              {paragraph}
                            </Paragraph>
                          )
                        })}
                      </Fragment>
                    )
                  }
                })}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

View.displayName = 'PageHome-View'

// Controller (handles global state, router, data fetching, etc. Feeds props to the view component)
const PageHome: FC<PageHomeProps> = (props) => {
  return <View {...props} />
}

PageHome.displayName = 'PageHome'

export default memo(PageHome)
