import { forwardRef, ImgHTMLAttributes, memo, useCallback, useMemo, useRef } from 'react'
import Image, { StaticImageData } from 'next/image'
import classNames from 'classnames'

import css from './BaseImage.module.scss'

import { noop } from '@/utils/basic-functions'

import useCombinedRefs from '@/hooks/use-combined-refs'

export interface BaseImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  // supports both src (for public images) or data (for imported images)
  nextImageClassName?: string
  src?: string
  data?: StaticImageData
  fill?: boolean
  objectFit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down'
  alt?: string
  priority?: boolean
  loading?: 'lazy' | 'eager' | undefined
  style?: React.CSSProperties
  onLoad?: () => void
}

const BaseImage = forwardRef<HTMLImageElement, BaseImageProps>(
  (
    {
      className,
      nextImageClassName,
      src,
      data,
      fill = true,
      objectFit = 'cover',
      alt = '',
      sizes,
      priority = false,
      loading = 'lazy',
      style,
      onLoad = noop
    },
    ref
  ) => {
    const rootRef = useRef<HTMLImageElement>(null)
    const combinedRef = useCombinedRefs(ref, rootRef)
    const imgSrc = useMemo(() => (data?.src || src)!, [data, src])

    const handleLoad = useCallback(() => {
      onLoad()
    }, [onLoad])

    return (
      <div className={classNames('BaseImage', css.root, className)} style={{ display: 'flex' }} ref={combinedRef}>
        <Image
          className={classNames('NextImage', css.nextImage, nextImageClassName, css[objectFit])}
          src={imgSrc}
          sizes={sizes}
          alt={alt}
          style={style}
          fill={fill}
          width={data?.width}
          height={data?.height}
          onLoad={handleLoad}
          priority={priority}
          loading={priority ? 'eager' : loading}
          unoptimized={true} // @TODO: Optimize images in the future - there is a bug with next/image and next export
        />
      </div>
    )
  }
)

BaseImage.displayName = 'BaseImage'

export default memo(BaseImage)
